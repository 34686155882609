.section_client {
  padding: 10px 20px 0px 20px;
}

.section_client .our-client {
  margin-bottom: 20px;
  padding: 0px 30px;
}

.section_client .our-client .client-inner {
  position: relative;
  padding: 20px;
  box-shadow: 1px 1px 4px 0px #00000040;
  width: 100%;
  text-align: center;
}

.section_client .our-client .client-inner img {
  max-width: 125px;
  max-height: 45px;
  object-fit: contain;
  margin: 0 auto;
  text-align: center;
  min-height: 45px;
}

.section_client h2{
  font-size: 25px;
  padding-left: 10px;
}

@media screen and (max-width:767px) {
  .section_client .our-client .client-inner {
    padding: 10px;
  }

  .section_client {
    padding: 0px 0px 20px 0px;
  }

  .section_client .our-client {
    margin-bottom: 20px;
    padding: 0px 20px;
  }
}